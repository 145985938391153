import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import Footer from "./components/footer/Footer";
import Colors from "./constants/Colors";
import AnalysisPage from "./pages/AnalysisPage";
import ReactstrapNavbar from "./components/navbar/ReactstrapNavbar";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import PopularPage from "./pages/PopularPage";
import { GoogleAnalyticsService } from "./services/GoogleAnalyticsService";
import SpeechMetaDataPage from "./pages/SpeechMetaDataPage";
import SpeechSummaryPage from "./pages/SpeechSummaryPage";

interface ComponentProps extends RouteComponentProps<any> {
  location: any;
}

const About: React.FunctionComponent = () => {
  return <AboutPage />;
};

const Popular: React.FunctionComponent = () => {
  return <PopularPage />;
};

const SpeechMetaData: React.FunctionComponent = () => {
  return <SpeechMetaDataPage />;
};

const SpeechSummary: React.FunctionComponent = () => {
  return <SpeechSummaryPage />;
};

const Analysis: React.FunctionComponent<ComponentProps> = ({ location }) => {
  return <AnalysisPage query={location.search} />;
};

const App: React.FunctionComponent = () => {
  useEffect(() => {
    GoogleAnalyticsService.initGoogleAnalytics();
  });

  return (
    <PrismicProvider client={client}>
      <Router>
        <div style={pageWrapper} className="App">
          <ReactstrapNavbar />
          <Route exact path="/" component={Analysis} />
          <Route path="/om-siden/" component={About} />
          <Route path="/kendte-ord/" component={Popular} />
          <Route path="/tale-meta-data/" component={SpeechMetaData} />
          <Route path="/tale-resume/" component={SpeechSummary} />
          <Footer />
        </div>
      </Router>
    </PrismicProvider>
  );
};

const pageWrapper = {
  position: "absolute",
  left: 0,
  width: "100%",
  minHeight: "100%",
  backgroundColor: Colors.lightThemeColor,
} as React.CSSProperties;

export default App;
