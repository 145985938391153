import React, { useEffect, useState } from "react";
import Dot from "../components/generalcomponents/Dot";
import Colors from "../constants/Colors";
import Headline from "../components/typography/Headline";
import Subtitle from "../components/typography/Subtitle";
import ContainerRow from "../components/generalcomponents/ContainerRow";
import { withRouter } from "react-router-dom";
import MotionContainer from "../components/generalcomponents/MotionContainer";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { Col, Row } from "reactstrap";
import { Speech } from "../models/Speech";
import { SpeechDataService } from "../services/SpeechDataService";
import MotionRow from "../components/generalcomponents/MotionRow";
import { GoogleAnalyticsService } from "../services/GoogleAnalyticsService";
import SpeechComparisonTable from "../components/tables/SpeechComparisonTable";

function SpeechMetaDataPage() {
  const [documents] = useAllPrismicDocumentsByType("speech");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isCorrectPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [speeches, setSpeeches] = useState<Speech[]>([]);

  useEffect(() => {
    if (!documents || documents.length === 0) {
      setLoading(true);
    }

    setSpeeches(SpeechDataService.getSpeechesFromDocuments(documents));

    if (documents && documents.length > 0) {
      setLoading(false);
    }

    if (isCorrectPassword) {
      setShowFilter(false);
    }
  }, [documents, isCorrectPassword]);

  useEffect(() => {
    GoogleAnalyticsService.trackGoogleAnalyticsPageview(
      window.location.pathname,
      "SpeechMetaDataPage"
    );
  }, []);

  return (
    <div>
      <div style={headlineWrapper}>
        <div className="container">
          <div className="text-center" style={headlinePadding}>
            <Headline>
              Talerne i tal
              <Dot />
            </Headline>
            <Subtitle text="Analyse af teksterne" />
          </div>
        </div>
      </div>

      {speeches && speeches.length > 0 && !loading && (
        <div className={showFilter ? "backFilter" : ""}>
          <MotionContainer>
            <ContainerRow>
              <MotionRow lg="12">
                <Row>
                  <Col>
                    <br></br>
                    <SpeechComparisonTable speeches={speeches} word="Danmark" />
                  </Col>
                </Row>
              </MotionRow>
            </ContainerRow>
          </MotionContainer>
        </div>
      )}
    </div>
  );
}

const headlineWrapper = {
  backgroundColor: Colors.themeColor,
  paddingTop: 50,
  paddingBottom: 40,
} as React.CSSProperties;

const headlinePadding = {
  paddingBottom: 20,
} as React.CSSProperties;

export default withRouter(SpeechMetaDataPage);
