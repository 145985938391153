import { Speech } from "../models/Speech";

const getNumberOfWordsInSpeech = (speech: Speech): number =>
  speech.speech_text[0].text ? speech.speech_text[0].text.split(" ").length : 0;

const getNumberOfCharactersInSpeech = (speech: Speech): number =>
  speech.speech_text[0].text ? speech.speech_text[0].text.length : 0;

const calculateAverageWordCount = (speeches: Speech[]) => {
  const speechesText = speeches.map((speech) => speech.speech_text[0].text);

  if (speechesText.length === 0) {
    return 0;
  }

  const totalWordCount = speechesText.reduce((total, str) => {
    if (typeof str === "undefined") {
      return 0;
    }

    const wordCount = str.trim().split(/\s+/).length; // Split on whitespace
    return total + wordCount;
  }, 0);

  return totalWordCount / speechesText.length;
};

const calculatePercentage = (part: number, total: number): number => {
  if (total === 0) return 0;
  return (part / total) * 100;
};

const calculatePercentageDifference = (
  value1: number,
  value2: number
): number => {
  if (value1 === 0 && value2 === 0) return 0;
  const difference = Math.abs(value1 - value2);
  const average = (value1 + value2) / 2;
  return (difference / average) * 100;
};

export const SpeechMetaDataService = {
  getNumberOfWordsInSpeech,
  getNumberOfCharactersInSpeech,
  calculateAverageWordCount,
  calculatePercentage,
  calculatePercentageDifference,
};
