import React from "react";
import ContainerRow from "../generalcomponents/ContainerRow";
import MotionContainer from "../generalcomponents/MotionContainer";
import MotionRow from "../generalcomponents/MotionRow";
import { Speech } from "../../models/Speech";
import SummaryComponent from "./SummaryComponent";

interface ComponentProps {
  speeches: Speech[];
}

const SpeechSummarySection: React.FunctionComponent<ComponentProps> = ({
  speeches,
}) => {
  return (
    <MotionContainer>
      <ContainerRow>
        <MotionRow lg="12">
          {speeches &&
            speeches.length > 0 &&
            speeches.map((item: Speech, key) => (
              <SummaryComponent
                key={key}
                year={item.speech_year[0].text}
                summary={item.speech_summary}
              />
            ))}
        </MotionRow>
      </ContainerRow>
    </MotionContainer>
  );
};

export default SpeechSummarySection;
