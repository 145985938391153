import React, { useEffect, useState } from "react";
import ContainerRow from "../generalcomponents/ContainerRow";
import MotionContainer from "../generalcomponents/MotionContainer";
import MotionRow from "../generalcomponents/MotionRow";
import SectionHeadline from "../typography/SectionHeadline";
import { Speech } from "../../models/Speech";
import { SanitizeService } from "../../services/SanitizeService";
import { HighlightService } from "../../services/HighlightService";
import { HighlightData } from "../../models/HighlightData";
import HighlightTextComponent from "./HighlightTextComponent";

interface ComponentProps {
  word: string;
  speeches: Speech[];
}

const SpeechHighlightSection: React.FunctionComponent<ComponentProps> = ({
  word,
  speeches,
}) => {
  const [highlightData, setHighlightData] = useState<HighlightData[]>([]);

  useEffect(() => {
    const sanitizedWord = SanitizeService.sanitizeInputString(word);
    let highlightDataCollection: HighlightData[] = [];
    let index: number = 1;

    speeches.forEach((element) => {
      let speechText = element.speech_text[0].text
        ? element.speech_text[0].text
        : "";

      let matches = HighlightService.findAllWordIndexes(speechText, word);

      matches.forEach((match) => {
        let substringToShow = HighlightService.getSubstring(
          speechText,
          HighlightService.adjustStartIndex(match),
          HighlightService.adjustEndIndex(
            match,
            HighlightService.getLastCharacterIndex(speechText)
          )
        );

        let highlightData: HighlightData = {
          id: index,
          searchWord: sanitizedWord,
          speech_text: speechText,
          year: element.speech_year[0].text ? element.speech_year[0].text : "",
          speaker: element.speaker,
          speech_date: new Date(element.speech_date),
          substringToShow: substringToShow,
        };

        highlightDataCollection.push(highlightData);

        index = index + 1;
      });
    });

    highlightDataCollection.sort((a, b) => {
      const dateA = new Date(a.speech_date).getTime() || 0;
      const dateB = new Date(b.speech_date).getTime() || 0;
      return dateB - dateA;
    });

    setHighlightData(highlightDataCollection);
  }, [word, speeches]);

  return (
    <MotionContainer>
      <ContainerRow>
        <MotionRow lg="12">
          <SectionHeadline showDot={true}>Se ordet i talerne</SectionHeadline>
        </MotionRow>
      </ContainerRow>
      <ContainerRow>
        <MotionRow lg="12">
          {highlightData &&
            highlightData.length > 0 &&
            highlightData.map((item: HighlightData, key) => (
              <HighlightTextComponent key={item.id} item={item} />
            ))}
        </MotionRow>
      </ContainerRow>
    </MotionContainer>
  );
};

export default SpeechHighlightSection;
