import React, { useState } from "react";
import Colors from "../../constants/Colors";
import { FaHashtag } from "react-icons/fa";
import Variables from "../../constants/Variables";

interface ComponentProps {
  text: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const TagButton: React.FunctionComponent<ComponentProps> = ({
  text,
  onClick,
}) => {
  const [isHovered, setHovered] = useState(false);

  const defaultStyle = {
    color: Colors.white,
    backgroundColor: Colors.themeColor,
    fontFamily: Variables.fontFamilyHeadline,
    fontSize: 14,
    paddingRight: 14,
    paddingLeft: 14,
    paddingTop: 12,
    paddingBottom: 10,
    marginRight: 10,
    borderRadius: 10,
    outline: "none",
    opacity: isHovered ? 0.8 : 1,
    marginTop: 10,
  } as React.CSSProperties;

  return (
    <button
      style={defaultStyle}
      onClick={(event) => onClick(event)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FaHashtag style={icon} size={12} />
      {text}
    </button>
  );
};

const icon = {
  color: Colors.white,
  marginRight: 5,
  marginBottom: 3,
} as React.CSSProperties;

export default TagButton;
