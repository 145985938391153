import React, { useEffect } from "react";
import SectionHeadline from "../typography/SectionHeadline";
import Card from "../cards/Card";
import { RichTextField } from "@prismicio/types";
import { PrismicRichText } from "@prismicio/react";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";

interface Props {
  year: string | undefined;
  summary: RichTextField;
}

const SummaryComponent: React.FunctionComponent<Props> = ({
  year,
  summary,
}) => {
  useEffect(() => {}, []);

  const defaultStyling = {
    color: Colors.almostBlack,
    fontFamily: Variables.fontFamilyText,
    fontSize: 17,
    marginBottom: 10,
    marginTop: 10,
  };

  const components = {
    paragraph: ({ children }: any) => <p style={defaultStyling}>{children}</p>,
  };

  return (
    <div>
      <SectionHeadline showDot={true} marginTop={15}>
        {year === undefined ? "" : year}
      </SectionHeadline>
      <Card>
        <PrismicRichText field={summary} components={components} />
      </Card>
    </div>
  );
};

export default SummaryComponent;
