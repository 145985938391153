import React, { useEffect } from "react";
import { HighlightData } from "../../models/HighlightData";
import SectionHeadline from "../typography/SectionHeadline";
import Card from "../cards/Card";
import Highlighter from "react-highlight-words";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";

interface Props {
  item: HighlightData;
}

const HighlightTextComponent: React.FunctionComponent<Props> = ({ item }) => {
  useEffect(() => {}, []);

  const defaultStyling = {
    color: Colors.almostBlack,
    fontFamily: Variables.fontFamilyText,
    fontSize: Variables.fontSizeSubtitle,
    marginBottom: 0,
  };

  const highlightStyling = {
    color: Colors.almostBlack,
    fontFamily: Variables.fontFamilyText,
    fontSize: Variables.fontSizeSubtitle,
    marginBottom: 0,
    backgroundColor: Colors.dotcolor,
  };

  return (
    <div>
      <SectionHeadline showDot={true} marginTop={15}>
        {item.year}
      </SectionHeadline>
      <Card>
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={[item.searchWord]}
          autoEscape={true}
          textToHighlight={item.substringToShow}
          unhighlightStyle={defaultStyling}
          highlightStyle={highlightStyling}
        />
      </Card>
    </div>
  );
};

export default HighlightTextComponent;
