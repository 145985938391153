import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Speech } from "../../models/Speech";
import { TableData } from "../../models/TableData";
import { SanitizeService } from "../../services/SanitizeService";
import { SpeechAnalysisService } from "../../services/SpeechAnalysisService";
import Colors from "../../constants/Colors";
import Card from "../cards/Card";
import Variables from "../../constants/Variables";
import { isMobile } from "react-device-detect";

interface ComponentProps {
  word: string;
  speeches: Speech[];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const VerticalBarChart: React.FunctionComponent<ComponentProps> = ({
  word,
  speeches,
}) => {
  const [tableData, setTableData] = useState<TableData[]>([]);

  useEffect(() => {
    const sanitizedWord = SanitizeService.sanitizeInputString(word);
    let speechTableData: TableData[] = [];
    let index: number = 1;

    speeches.forEach((element) => {
      if (sanitizedWord.includes(" ")) {
        let tableData: TableData = {
          id: index,
          word: sanitizedWord,
          year: element.speech_year[0].text ? element.speech_year[0].text : "",
          mentions: SpeechAnalysisService.getTimesMentionedInStringWithSpace(
            sanitizedWord + " ",
            element.speech_text[0].text ? element.speech_text[0].text : ""
          ),
          speech_date: new Date(element.speech_date),
          speaker: element.speaker,
        };

        speechTableData.push(tableData);
      } else {
        let tableData: TableData = {
          id: index,
          word: sanitizedWord,
          year: element.speech_year[0].text ? element.speech_year[0].text : "",
          mentions: SpeechAnalysisService.getTimesMentionedInString(
            sanitizedWord,
            element.speech_text[0].text ? element.speech_text[0].text : ""
          ),
          speech_date: new Date(element.speech_date),
          speaker: element.speaker,
        };

        speechTableData.push(tableData);
      }

      index = index + 1;
    });

    speechTableData.sort((a, b) => {
      const dateA = new Date(a.speech_date).getTime() || 0;
      const dateB = new Date(b.speech_date).getTime() || 0;
      return dateA - dateB;
    });

    setTableData(speechTableData);
  }, [word, speeches]);

  Chart.defaults.font.size = isMobile ? 10 : 18;

  const options: any = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tableData: TableData) {
            return tableData.year;
          },
        },
      },
      datalabels: {
        color: "white",
        font: {
          family: Variables.fontFamilyHeadline,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        suggestedMin: 0,
        ticks: {
          precision: 0,
        },
        grid: {
          display: false,
        },
      },
    },
  };
  // The following colors will be used sequentially for the chart bars
  const backgroundColors = [Colors.themeColor];
  const data: any = {
    labels: tableData.map((item) => item.year),
    datasets: [
      {
        label: tableData.map((item) => item.year),
        data: tableData.map((item) => item.mentions),
        backgroundColor: backgroundColors,
        borderWidth: 1,
        barThickness: isMobile ? 16 : 30,
      },
    ],
  };

  return (
    <Card>
      <div style={{ height: "300px" }}>
        <Bar data={data} options={options} />
      </div>
    </Card>
  );
};

export default VerticalBarChart;
