import React from "react";
import Colors from "../../constants/Colors";
import SectionHeadline from "../typography/SectionHeadline";
import TagButton from "../buttons/TagButton";
import { SearchState } from "../../models/SearchState";

interface Props {
  setSearchState: React.Dispatch<React.SetStateAction<SearchState>>;
}

export default function Examples({ setSearchState }: Props) {
  return (
    <div style={container}>
      <div className="container">
        <div className="col-md-8 offset-md-2 text-center">
          <div className="mb-2">
            <SectionHeadline showDot={true} marginTop={0}>
              Se eksempler på ord og odds
            </SectionHeadline>
          </div>
          <div className="inline-flex space-x-4">
            <TagButton
              text="Europa - Odds 2.00"
              onClick={() => {
                setSearchState({
                  searchQuery: "europa",
                  analysisQuery: "europa",
                  bettingNumber: "2.00",
                  showClearTextIcon: true,
                });
              }}
            />
            <TagButton
              text="Fremtiden - Odds 1.30"
              onClick={() => {
                setSearchState({
                  searchQuery: "fremtiden",
                  analysisQuery: "fremtiden",
                  bettingNumber: "1.30",
                  showClearTextIcon: true,
                });
              }}
            />
            <TagButton
              text="Politiet - Odds 1.60"
              onClick={() => {
                setSearchState({
                  searchQuery: "politiet",
                  analysisQuery: "politiet",
                  bettingNumber: "1.60",
                  showClearTextIcon: true,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const container = {
  backgroundColor: Colors.lightThemeColor,
  paddingTop: 0,
  marginBottom: 20,
} as React.CSSProperties;
