export default {
  themeColor: "#092637",
  lightThemeColor: "#F5F5F5",
  dotcolor: "#FAC72A",
  headlineColor: "#FFF",
  subtitleColor: "#FFF",
  navigationLinks: "#FFF",

  // Pure colors
  white: "#fff",
  almostBlack: "#292929",
  grey: "#868c91",
  lightGrey: "#DBDBD9",
  red: "#AD0841",
  green: "#03644C",
};
