import React, { useEffect, useState } from "react";
import Dot from "../components/generalcomponents/Dot";
import Colors from "../constants/Colors";
import Headline from "../components/typography/Headline";
import Subtitle from "../components/typography/Subtitle";
import queryString, { ParsedQuery } from "query-string";
import ContainerRow from "../components/generalcomponents/ContainerRow";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalysisSearchBar from "../components/searchbar/AnalysisSearchBar";
import AnalysisInstructions from "../components/analysiscomponents/Instructions";
import MotionContainer from "../components/generalcomponents/MotionContainer";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import TimesMentionedCard from "../components/analysiscomponents/TimesMentionedCard";
import { Col, Row } from "reactstrap";
import TableComponent from "../components/tables/TableComponent";
import { Speech } from "../models/Speech";
import { SpeechDataService } from "../services/SpeechDataService";
import YearMentionsCard from "../components/analysiscomponents/MentionsOutOfYearsCard";
import YearMentionsPercentageCard from "../components/analysiscomponents/YearMentionsPercentageCard";
import SectionHeadline from "../components/typography/SectionHeadline";
import MotionRow from "../components/generalcomponents/MotionRow";
import AverageMentionsCard from "../components/analysiscomponents/AverageMentionsCard";
import OverUnderCard from "../components/analysiscomponents/OverUnderCard";
import PasswordForm from "../components/forms/PasswordForm";
import InlineImageCard from "../components/cards/InlineImageCard";
import QueenMargretheImage from "../assets/dronning-margrethe.jpeg";
import KingFrederikImage from "../assets/kong-frederik.jpeg";
import NoSpeechesFoundComponent from "../components/analysiscomponents/NoSpeechesFoundComponent";
import { GoogleAnalyticsService } from "../services/GoogleAnalyticsService";
import Examples from "../components/analysiscomponents/Examples";
import { SearchState } from "../models/SearchState";
import VerticalBarChart from "../components/charts/VerticalBarChart";
import SpeechHighlightSection from "../components/analysiscomponents/SpeechHighlightSection";

interface Props extends RouteComponentProps<any> {
  query: string;
  history: any;
}

function AnalysisPage({ query, history }: Props) {
  const [documents] = useAllPrismicDocumentsByType("speech");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [queenIsSelected, setQueenIsSelected] = useState<boolean>(true);
  const [kingIsSelected, setKingIsSelected] = useState<boolean>(true);
  const [isCorrectPassword, setIsCorrectPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [speeches, setSpeeches] = useState<Speech[]>([]);
  const [searchState, setSearchState] = useState<SearchState>({
    searchQuery: "",
    analysisQuery: "",
    bettingNumber: "1.00",
    showClearTextIcon: false,
  });

  useEffect(() => {
    if (!documents || documents.length === 0) {
      setLoading(true);
    }

    var speeches = SpeechDataService.getSpeechesFromDocuments(documents);
    var filteredSpeeches = SpeechDataService.filterSpeechesForSpeakers(
      speeches,
      queenIsSelected,
      kingIsSelected
    );

    filteredSpeeches.sort((a, b) => {
      const dateA = new Date(a.speech_date).getTime() || 0;
      const dateB = new Date(b.speech_date).getTime() || 0;
      return dateB - dateA;
    });

    setSpeeches(filteredSpeeches);

    const values: ParsedQuery<string> = queryString.parse(query);

    if (values.ord) {
      const word: string = values.ord.toString();

      setSearchState({
        searchQuery: word,
        analysisQuery: word,
        bettingNumber: searchState.bettingNumber,
        showClearTextIcon: true,
      });
    }

    if (documents && documents.length > 0) {
      setLoading(false);
    }

    if (isCorrectPassword) {
      setShowFilter(false);
    }

    GoogleAnalyticsService.trackGoogleAnalyticsPageview(
      window.location.pathname,
      "AnalysisPage"
    );
  }, [
    query,
    documents,
    searchState.bettingNumber,
    isCorrectPassword,
    queenIsSelected,
    kingIsSelected,
  ]);

  useEffect(() => {
    GoogleAnalyticsService.trackGoogleAnalyticsPageview(
      window.location.pathname,
      "AnalysisPage"
    );
  }, []);

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (event.target.value.length < 1) {
      clearText();
    } else {
      setSearchState({
        ...searchState,
        searchQuery: event.target.value,
        showClearTextIcon: true,
      });
    }
  };

  const handleNumberChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setSearchState({
      ...searchState,
      bettingNumber: event.target.value,
      showClearTextIcon: true,
    });

    history.push("?ord=" + searchState.searchQuery);
  };

  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    setSearchState({
      ...searchState,
      bettingNumber: searchState.bettingNumber,
      analysisQuery: searchState.analysisQuery,
    });

    history.push("?ord=" + searchState.searchQuery);

    GoogleAnalyticsService.triggerGoogleAnalyticsEvent(
      "Search",
      "search_form_submit",
      searchState.searchQuery
    );
  };

  const clearText = (): void => {
    setSearchState({
      ...searchState,
      searchQuery: "",
      analysisQuery: "",
      showClearTextIcon: false,
    });

    history.push("/");
  };

  return (
    <div>
      <div style={headlineWrapper}>
        <div className="container">
          <div className="text-center" style={headlinePadding}>
            <Headline>
              Odds på Nytårstalen
              <Dot />
            </Headline>
            <Subtitle text="Skriv et ord og indtast et odds" />
          </div>
        </div>
        <ContainerRow centered={true}>
          <div className="col-md-6 col-lg-6 offset-md-3 offset-lg-3">
            <AnalysisSearchBar
              value={searchState.searchQuery}
              numberValue={searchState.bettingNumber}
              placeholder="Søg på et ord.."
              handleSubmit={(event) => handleSubmit(event)}
              handleChange={(event: any) => handleChange(event)}
              handleNumberChange={(event: any) => handleNumberChange(event)}
            />
          </div>
        </ContainerRow>
        <ContainerRow>
          <div className="col-md-3 col-lg-3 offset-md-3 offset-lg-3">
            <InlineImageCard
              imageSource={QueenMargretheImage}
              text="Dronning Margrethe"
              isSelected={queenIsSelected}
              handleClick={(event: any) => {
                setQueenIsSelected(!queenIsSelected);
                handleSubmit(event);
              }}
            />
          </div>
          <div className="col-md-3 col-lg-3">
            <InlineImageCard
              imageSource={KingFrederikImage}
              text="Kong Frederik 10."
              isSelected={kingIsSelected}
              handleClick={(event: any) => {
                setKingIsSelected(!kingIsSelected);
                handleSubmit(event);
              }}
            />
          </div>
        </ContainerRow>
      </div>

      {searchState.analysisQuery === "" && !loading && (
        <div>
          <br></br>
          <Examples setSearchState={setSearchState} />
          <AnalysisInstructions />
        </div>
      )}

      {searchState.analysisQuery !== "" &&
        !loading &&
        speeches.length === 0 && (
          <div>
            <br></br>
            <NoSpeechesFoundComponent />
          </div>
        )}

      {speeches &&
        speeches.length > 0 &&
        searchState.analysisQuery !== "" &&
        !loading && (
          <>
            {showFilter && (
              <div className="col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                <PasswordForm setIsCorrectPassword={setIsCorrectPassword} />
              </div>
            )}

            <div className={showFilter ? "backFilter" : ""}>
              <MotionContainer>
                <br></br>
                <ContainerRow>
                  <TimesMentionedCard
                    word={searchState.analysisQuery}
                    speeches={speeches}
                    text="Gange nævnt i talen"
                  />
                  <YearMentionsCard
                    word={searchState.analysisQuery}
                    speeches={speeches}
                    text="Taler, hvor ordet er nævnt"
                  />
                  <YearMentionsPercentageCard
                    word={searchState.analysisQuery}
                    speeches={speeches}
                    text="Sandsynlighed"
                    bettingNumber={parseFloat(searchState.bettingNumber)}
                  />
                  <MotionRow lg="12">
                    <SectionHeadline showDot={true} marginTop={15}>
                      De sidste 3 år
                    </SectionHeadline>
                  </MotionRow>
                  <TimesMentionedCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 3)}
                    text="Gange nævnt i talen"
                  />
                  <YearMentionsCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 3)}
                    text="Taler, hvor ordet er nævnt"
                  />
                  <YearMentionsPercentageCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 3)}
                    text="Sandsynlighed"
                    bettingNumber={parseFloat(searchState.bettingNumber)}
                  />
                  <MotionRow lg="12">
                    <SectionHeadline showDot={true} marginTop={15}>
                      De sidste 5 år
                    </SectionHeadline>
                  </MotionRow>
                  <TimesMentionedCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 5)}
                    text="Gange nævnt i talen"
                  />
                  <YearMentionsCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 5)}
                    text="Taler, hvor ordet er nævnt"
                  />
                  <YearMentionsPercentageCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 5)}
                    text="Sandsynlighed"
                    bettingNumber={parseFloat(searchState.bettingNumber)}
                  />
                  <MotionRow lg="12">
                    <SectionHeadline showDot={true} marginTop={15}>
                      De sidste 8 år
                    </SectionHeadline>
                  </MotionRow>
                  <TimesMentionedCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 8)}
                    text="Gange nævnt i talen"
                  />
                  <YearMentionsCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 8)}
                    text="Taler, hvor ordet er nævnt"
                  />
                  <YearMentionsPercentageCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 8)}
                    text="Sandsynlighed"
                    bettingNumber={parseFloat(searchState.bettingNumber)}
                  />
                  <MotionRow lg="12">
                    <SectionHeadline showDot={true} marginTop={15}>
                      De sidste 10 år
                    </SectionHeadline>
                  </MotionRow>
                  <TimesMentionedCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 10)}
                    text="Gange nævnt i talen"
                  />
                  <YearMentionsCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 10)}
                    text="Taler, hvor ordet er nævnt"
                  />
                  <YearMentionsPercentageCard
                    word={searchState.analysisQuery}
                    speeches={speeches.slice(0, 10)}
                    text="Sandsynlighed"
                    bettingNumber={parseFloat(searchState.bettingNumber)}
                  />
                </ContainerRow>
              </MotionContainer>
            </div>
          </>
        )}

      {speeches &&
        speeches.length > 0 &&
        searchState.analysisQuery !== "" &&
        !loading && (
          <div className={showFilter ? "backFilter" : ""}>
            <MotionContainer>
              <ContainerRow>
                <MotionRow lg="12">
                  <SectionHeadline showDot={true}>
                    Graf over data
                  </SectionHeadline>
                </MotionRow>
              </ContainerRow>
              <ContainerRow>
                <MotionRow lg="12">
                  <Row>
                    <Col>
                      <VerticalBarChart
                        word={searchState.analysisQuery}
                        speeches={speeches}
                      />
                    </Col>
                  </Row>
                </MotionRow>
              </ContainerRow>
            </MotionContainer>
          </div>
        )}

      {speeches &&
        speeches.length > 0 &&
        searchState.analysisQuery !== "" &&
        !loading && (
          <div className={showFilter ? "backFilter" : ""}>
            <MotionContainer>
              <ContainerRow>
                <MotionRow lg="12">
                  <SectionHeadline showDot={true}>
                    Data fra alle år
                  </SectionHeadline>
                </MotionRow>
              </ContainerRow>
              <ContainerRow>
                <MotionRow lg="12">
                  <Row>
                    <Col>
                      <TableComponent
                        word={searchState.analysisQuery}
                        speeches={speeches}
                      />
                    </Col>
                  </Row>
                </MotionRow>
              </ContainerRow>
            </MotionContainer>
          </div>
        )}

      {speeches &&
        speeches.length > 0 &&
        searchState.analysisQuery !== "" &&
        !loading && (
          <div className={showFilter ? "backFilter" : ""}>
            <MotionContainer>
              <ContainerRow>
                <MotionRow lg="12">
                  <SectionHeadline showDot={true} marginTop={15}>
                    Estimater på over/under
                  </SectionHeadline>
                </MotionRow>
                <AverageMentionsCard
                  word={searchState.analysisQuery}
                  speeches={speeches}
                  text="Total gennemsnit"
                />
                <AverageMentionsCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 3)}
                  text="Gennemsnit - Sidste 3 år"
                />
                <AverageMentionsCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 5)}
                  text="Gennemsnit - Sidste 5 år"
                />
                <AverageMentionsCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 10)}
                  text="Gennemsnit - Sidste 10 år"
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches}
                  overUnder={1.5}
                  text="Antal over 1,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 3)}
                  overUnder={1.5}
                  text="Antal over 1,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 5)}
                  overUnder={1.5}
                  text="Antal over 1,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 10)}
                  overUnder={1.5}
                  text="Antal over 1,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches}
                  overUnder={2.5}
                  text="Antal over 2,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 3)}
                  overUnder={2.5}
                  text="Antal over 2,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 5)}
                  overUnder={2.5}
                  text="Antal over 2,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 10)}
                  overUnder={2.5}
                  text="Antal over 2,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches}
                  overUnder={3.5}
                  text="Antal over 3,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 3)}
                  overUnder={3.5}
                  text="Antal over 3,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 5)}
                  overUnder={3.5}
                  text="Antal over 3,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 10)}
                  overUnder={3.5}
                  text="Antal over 3,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches}
                  overUnder={4.5}
                  text="Antal over 4,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 3)}
                  overUnder={4.5}
                  text="Antal over 4,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 5)}
                  overUnder={4.5}
                  text="Antal over 4,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 10)}
                  overUnder={4.5}
                  text="Antal over 4,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches}
                  overUnder={5.5}
                  text="Antal over 5,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 3)}
                  overUnder={5.5}
                  text="Antal over 5,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 5)}
                  overUnder={5.5}
                  text="Antal over 5,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 10)}
                  overUnder={5.5}
                  text="Antal over 5,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches}
                  overUnder={6.5}
                  text="Antal over 6,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 3)}
                  overUnder={6.5}
                  text="Antal over 6,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 5)}
                  overUnder={6.5}
                  text="Antal over 6,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
                <OverUnderCard
                  word={searchState.analysisQuery}
                  speeches={speeches.slice(0, 10)}
                  overUnder={6.5}
                  text="Antal over 6,5"
                  bettingNumber={parseFloat(searchState.bettingNumber)}
                />
              </ContainerRow>
            </MotionContainer>
          </div>
        )}

      {speeches &&
        speeches.length > 0 &&
        searchState.analysisQuery !== "" &&
        !loading && (
          <div className={showFilter ? "backFilter" : ""}>
            <SpeechHighlightSection
              speeches={speeches}
              word={searchState.analysisQuery}
            />
          </div>
        )}
    </div>
  );
}

const headlineWrapper = {
  backgroundColor: Colors.themeColor,
  paddingTop: 50,
  paddingBottom: 40,
} as React.CSSProperties;

const headlinePadding = {
  paddingBottom: 20,
} as React.CSSProperties;

export default withRouter(AnalysisPage);
