import React, { useEffect } from "react";
import CardTitle from "../typography/CardTitle";
import { SpeechMetaDataTableData } from "../../models/SpeechMetaDataTableData";
import Colors from "../../constants/Colors";

interface Props {
  item: SpeechMetaDataTableData;
}

const SpeechComparisonTableItem: React.FunctionComponent<Props> = ({
  item,
}) => {
  useEffect(() => {}, []);

  const percentageStyling = {
    fontSize: 17,
    paddingBottom: 0,
    marginBottom: 0,
    lineHeight: 1.8,
    color:
      item.speech_word_length > item.speech_average_word_length
        ? Colors.green
        : Colors.red,
  } as React.CSSProperties;

  return (
    <tr>
      <td>
        <CardTitle fontSize={17} marginBottom={0} lineHeight={1.8}>
          {item.year}
        </CardTitle>
      </td>
      <td>
        <h6 style={textStyling}>{item.speech_word_length.toString()}</h6>
      </td>
      <td>
        <h6 style={textStyling}>
          {Math.round(item.speech_average_word_length).toString()}
        </h6>
      </td>
      <td>
        {item.speech_word_length > item.speech_average_word_length && (
          <h6 style={percentageStyling}>
            + {Math.round(item.speech_length_percentage_difference).toString()}{" "}
            %
          </h6>
        )}
        {item.speech_word_length < item.speech_average_word_length && (
          <h6 style={percentageStyling}>
            - {Math.round(item.speech_length_percentage_difference).toString()}{" "}
            %
          </h6>
        )}
      </td>
      <td>
        <h6 style={textStyling}>{item.speaker}</h6>
      </td>
    </tr>
  );
};

const textStyling = {
  fontSize: 17,
  paddingBottom: 0,
  marginBottom: 0,
  lineHeight: 1.8,
} as React.CSSProperties;

export default SpeechComparisonTableItem;
