import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import Card from "../cards/Card";
import UppercaseColoredText from "../typography/UppercaseColoredText";
import { Speech } from "../../models/Speech";
import { SanitizeService } from "../../services/SanitizeService";
import { SpeechMetaDataTableData } from "../../models/SpeechMetaDataTableData";
import SpeechComparisonTableItem from "./SpeechComparisonTableItem";
import { SpeechMetaDataService } from "../../services/SpeechMetaDataService";

interface ComponentProps {
  word: string;
  speeches: Speech[];
}

const SpeechComparisonTable: React.FunctionComponent<ComponentProps> = ({
  word,
  speeches,
}) => {
  const [tableData, setTableData] = useState<SpeechMetaDataTableData[]>([]);

  useEffect(() => {
    const sanitizedWord = SanitizeService.sanitizeInputString(word);
    let speechTableData: SpeechMetaDataTableData[] = [];
    let index: number = 1;

    speeches.forEach((element) => {
      const numberOfWords =
        SpeechMetaDataService.getNumberOfWordsInSpeech(element);

      const averageWordCount =
        SpeechMetaDataService.calculateAverageWordCount(speeches);

      const numberOfCharacters =
        SpeechMetaDataService.getNumberOfCharactersInSpeech(element);

      const percentageDifference =
        SpeechMetaDataService.calculatePercentageDifference(
          numberOfWords,
          averageWordCount
        );

      let tableData: SpeechMetaDataTableData = {
        id: index,
        word: sanitizedWord,
        year: element.speech_year[0].text ? element.speech_year[0].text : "",
        speech_date: new Date(element.speech_date),
        speech_word_length: numberOfWords,
        speech_average_word_length: averageWordCount,
        speech_length_percentage_difference: percentageDifference,
        speech_character_length: numberOfCharacters,
        speaker: element.speaker,
      };

      speechTableData.push(tableData);

      index = index + 1;
    });

    speechTableData.sort((a, b) => {
      const dateA = new Date(a.speech_date).getTime() || 0;
      const dateB = new Date(b.speech_date).getTime() || 0;
      return dateB - dateA;
    });

    setTableData(speechTableData);
  }, [word, speeches]);

  return (
    <Card>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>
              <UppercaseColoredText>År</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>Antal ord</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>
                Antal ord - Gennemsnit
              </UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>Procent forskel</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>Taler</UppercaseColoredText>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData.length > 0 &&
            tableData.map((item: SpeechMetaDataTableData, key) => (
              <SpeechComparisonTableItem key={item.id} item={item} />
            ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default SpeechComparisonTable;
