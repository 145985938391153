import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";

const ReactstrapNavbar: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = (): void => setIsOpen(!isOpen);

  return (
    <div style={background}>
      <Container>
        <Navbar expand="md" light>
          <NavbarBrand href="/">
            <p style={linkStyles}>Hjem</p>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} style={togglerStyles} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/kendte-ord/" style={linkStyles}>
                  Kendte ord
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/tale-meta-data/" style={linkStyles}>
                  Talerne i tal
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/tale-resume/" style={linkStyles}>
                  Resumé af taler
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/om-siden/" style={linkStyles}>
                  Om siden
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

const background = {
  backgroundColor: Colors.themeColor,
} as React.CSSProperties;

const linkStyles = {
  color: Colors.navigationLinks,
  fontFamily: Variables.fontFamilyText,
  fontSize: 18,
  opacity: 0.8,
  textDecoration: "none",
  marginBottom: 0,
  marginTop: 6,
} as React.CSSProperties;

const togglerStyles = {
  backgroundColor: Colors.white,
  opacity: 0.8,
  textDecoration: "none",
} as React.CSSProperties;

export default ReactstrapNavbar;
