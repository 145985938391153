const findAllIndexes = (sentence: string, searchString: string): number[] => {
  sentence = sentence.replace(/\s+/g, " ").trim();
  searchString = searchString.replace(/\s+/g, " ").trim();
  sentence = sentence.replace(/\u00A0/g, " ");
  searchString = searchString.replace(/\u00A0/g, " ");

  const indexes: number[] = [];
  let startIndex = 0;

  while (startIndex < sentence.length) {
    const foundIndex = sentence.indexOf(searchString, startIndex);
    if (foundIndex === -1) break;

    indexes.push(foundIndex);
    startIndex = foundIndex + searchString.length;
  }

  return indexes;
};

const findAllWordIndexes = (
  sentence: string,
  searchString: string
): number[] => {
  sentence = sentence.replace(/\s+/g, " ").trim();
  searchString = searchString.replace(/\s+/g, " ").trim();
  sentence = sentence.replace(/\u00A0/g, " ");
  searchString = searchString.replace(/\u00A0/g, " ");

  const indexes: number[] = [];
  const regex = new RegExp(`\\b${searchString}\\b`, "gi");
  let match: RegExpExecArray | null;

  while ((match = regex.exec(sentence)) !== null) {
    indexes.push(match.index);
  }

  return indexes;
};

const adjustStartIndex = (index: number, offset: number = 150): number => {
  const newIndex = index - offset;

  if (newIndex < 0) {
    return 0;
  } else {
    return newIndex;
  }
};

const adjustEndIndex = (
  index: number,
  lastCharacterIndex: number,
  offset: number = 150
): number => {
  const newIndex = index + offset;

  if (newIndex > lastCharacterIndex) {
    return lastCharacterIndex + 1;
  } else {
    return newIndex;
  }
};

const getLastCharacterIndex = (str: string) => {
  if (str.length === 0) {
    return -1;
  }
  return str.length - 1;
};

const getSubstring = (str: string, startIndex: number, endIndex: number) => {
  return str.slice(startIndex, endIndex);
};

export const HighlightService = {
  findAllIndexes,
  findAllWordIndexes,
  adjustStartIndex,
  adjustEndIndex,
  getLastCharacterIndex,
  getSubstring,
};
